<template>
  <v-card class="card-shadow border-radius-xl">
    <common-loading
      :title="loading_dialog.title"
      :message="loading_dialog.message"
      :loading_dialog="loading_dialog.dialog"
      :show_loading="loading_dialog.show_loading"
      :show_confirm="loading_dialog.show_confirm"
      :confirm_function="loading_dialog.confirm_function"
      v-on:confirm="loading_dialog.dialog = false"
    >
    </common-loading>
    <common-confirm
      :title="confirm.title"
      :message="confirm.message"
      :confirm_dialog="confirm.dialog"
      :confirm_function="confirm.function"
      v-on:confirm="confirm.dialog = false"
      v-on:cancel="confirm.dialog = false"
    ></common-confirm>

    <!-- import dialog -->
    <v-dialog
      v-model="import_dialog"
      width="60%"
      height="80%"
      class="pa-0 ma-0"
    >
      <v-card class="ma-0 pa-0 d-flex flex-column">
        <v-card-text>
          <v-card-title class="justify-center ml-2 mr-4 py-2">
            <v-spacer></v-spacer>
            <v-btn icon @click="closeImportDialog()" class="pa-0 ma-0">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-row>
            <v-col cols="10">
              <label class="text-sm text-typo font-weight-bolder ms-1"
                >檔案</label
              >
              <v-file-input
                :accept="getSuffix()"
                label="上傳檔案"
                v-model="file"
                @change="onImageChange"
              ></v-file-input>
            </v-col>
            <v-col cols="2">
              <label class="text-sm text-typo font-weight-bolder ms-1"
                >Excel範本</label
              >
              <p></p>
              <a class="text-right" @click="downloadTemplates()">下載</a>
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col cols="4">
              <label class="text-sm text-typo font-weight-bolder ms-1"
                >類別</label
              >
              <v-radio-group row v-model="import_option">
                <v-radio
                  v-for="(item, index) in import_option_enum_str"
                  :key="index"
                  class="mx-0"
                  :label="item.text"
                  :value="item.value"
                ></v-radio>
              </v-radio-group>
            </v-col> -->

            <v-col cols="6" v-if="imageUrl">
              <label class="text-sm text-typo font-weight-bolder ms-1"
                >圖片</label
              >
              <cropper
                ref="cropper"
                class="cropper"
                :src="imageUrl"
                @change="change"
                :default-position="defaultPosition"
                :default-size="defaultSize"
              />
            </v-col>

            <v-col cols="6" v-if="imageUrl">
              <v-row>
                <v-col cols="12">
                  <label class="font-weight-bolder ms-1">資料類型</label>
                  <v-radio-group row v-model="data_source_option">
                    <v-radio
                      :ripple="false"
                      v-for="(item, i) in object_type_filter"
                      :key="i + item"
                      :label="item.text"
                      :value="item.value"
                      dense
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    class="
                      font-weight-bold
                      text-uppercase
                      btn-default
                      bg-gradient-secondary
                      py-2
                      px-6
                      me-2
                      mt-6
                      mb-2
                    "
                    color="#5e72e4"
                    @click="rotateImageLeft()"
                  >
                    <v-icon class="me-2" size="12">fa fa-undo</v-icon>
                    左轉</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    class="
                      font-weight-bold
                      text-uppercase
                      btn-default
                      bg-gradient-default
                      py-2
                      px-6
                      me-2
                      mt-6
                      mb-2
                    "
                    color="#5e72e4"
                    @click="rotateImageRight()"
                  >
                    <v-icon class="me-2" size="12">fa fa-redo</v-icon>
                    右轉</v-btn
                  >
                </v-col>
              </v-row>
              <!-- <v-btn
                elevation="0"
                :ripple="false"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-gradient-secondary
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                "
                color="#5e72e4"
                @click="rotateImageLeft()"
              >
                <v-icon class="me-2" size="12">fa fa-undo</v-icon>
                左轉</v-btn
              >
              <v-btn
                elevation="0"
                :ripple="false"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  bg-gradient-default
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                "
                color="#5e72e4"
                @click="rotateImageRight()"
              >
                <v-icon class="me-2" size="12">fa fa-redo</v-icon>
                右轉</v-btn
              > -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions bottom class="mx-4 my-0 py-0">
          <v-btn
            elevation="0"
            :ripple="false"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-secondary
              py-2
              px-6
              me-2
              mt-6
              mb-2
            "
            color="#5e72e4"
            @click="closeImportDialog()"
            >取消</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            :ripple="false"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-6
              mb-2
            "
            @click="onConfirm()"
            >確定</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title class="justify-center py-2 px-4">
      <h5 class="font-weight-bold text-h5 text-grt mb-0">客戶匯入</h5>
      <v-spacer></v-spacer>
    </v-card-title>
    <!-- preview image -->
    <v-dialog v-model="preview_dialog" max-width="80%" max-height="800">
      <v-card class="rounded-0">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="preview_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img contain :src="preview_photo" max-height="800"></v-img>
      </v-card>
    </v-dialog>
    <v-tabs v-model="tab" hide-slider class="border-radius-lg">
      <v-tabs-slider></v-tabs-slider>

      <v-tab :ripple="false" class="ms-auto" key="excel">
        Excel匯入 <v-icon class="me-2" large>mdi-file-excel</v-icon>
      </v-tab>

      <v-tab :ripple="false" class="me-auto" key="image">
        影像匯入
        <v-icon class="me-2" large>mdi-image</v-icon></v-tab
      >
      <v-tab-item key="excel" class="py-2">
        <v-card>
          <v-card-title class="justify-center py-2 px-4">
            <v-spacer></v-spacer>
            <v-btn
              :ripple="false"
              elevation="0"
              color="#fff"
              class="
                font-weight-bolder
                btn-default
                bg-gradient-primary
                py-5
                px-4
                mx-2
              "
              small
              @click="openImportDialog(0)"
            >
              上傳匯入Excel
              <v-icon right>mdi-upload</v-icon>
            </v-btn>
            <v-btn
              :ripple="false"
              elevation="0"
              color="#fff"
              class="
                font-weight-bolder
                btn-default btn-outline-secondary
                py-5
                px-4
                mx-2
              "
              small
              @click="reset()"
            >
              {{ $t("Reset") }}
              <v-icon right>mdi-close-circle-outline </v-icon>
            </v-btn>
            <v-btn
              :ripple="false"
              elevation="0"
              color="#fff"
              class="
                font-weight-bolder
                btn-default
                bg-gradient-success
                py-5
                px-4
                mx-2
              "
              small
              @click="onSubmit()"
              :disabled="items.length == 0"
            >
              送出
              <v-icon right> mdi-send </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="px-0 py-0">
            <v-simple-table class="table border-radius-xl">
              <template v-slot:top v-if="items.length > 0">
                <v-row class="px-4 py-2">
                  <v-col cols="4">
                    <label class="text-sm text-typo font-weight-bolder ms-1"
                      >資料來源</label
                    >
                    <v-text-field
                      v-model="data_source_str"
                      outlined
                      hide-details
                      class="
                        font-size-input
                        placeholder-er
                        border border-radius-md
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <label class="text-sm text-typo font-weight-bolder ms-1"
                      >群組</label
                    >
                    <treeselect
                      v-model="groups"
                      :multiple="true"
                      :flat="true"
                      :autoSelectAncestors="true"
                      :autoDeselectAncestors="true"
                      :default-expand-level="0"
                      :options="group_options"
                      :searchable="searchable"
                      class="
                        font-size-input
                        placeholder-er
                        border border-radius-md
                      "
                      placeholder="群組"
                      noChildrenText="無子群組"
                    />
                  </v-col>
                </v-row>
              </template>

              <template v-slot:default>
                <tbody>
                  <tr v-for="(item, i) in items" :key="i">
                    <td
                      :class="{ 'border-bottom': i != items.length - 1 }"
                      width="12%"
                      class="px-0"
                    >
                      <div class="font-weight-bold pl-2 text-md">
                        姓名/Email
                      </div>
                      <div class="text-md">
                        <v-text-field
                          v-model="item.full_name"
                          outlined
                          dense
                          hide-details
                          class="text-typo font-size-input border rounded-0"
                        ></v-text-field>
                      </div>
                      <div class="text-md">
                        <v-text-field
                          v-model="item.email"
                          outlined
                          dense
                          hide-details
                          class="text-typo font-size-input border rounded-0"
                        ></v-text-field>
                      </div>
                    </td>
                    <td
                      :class="{ 'border-bottom': i != items.length - 1 }"
                      class="px-0"
                      width="10%"
                    >
                      <div class="font-weight-bold pl-2 text-md">手機</div>
                      <div class="text-md">
                        <v-text-field
                          v-model="item.mobile_1"
                          outlined
                          dense
                          hide-details
                          class="text-typo font-size-input border rounded-0"
                        ></v-text-field>
                      </div>
                      <div class="text-md">
                        <v-text-field
                          v-model="item.mobile_2"
                          outlined
                          dense
                          hide-details
                          class="ext-typo font-size-input border rounded-0"
                        ></v-text-field>
                      </div>
                    </td>
                    <td
                      :class="{ 'border-bottom': i != items.length - 1 }"
                      class="px-0"
                      width="10%"
                    >
                      <div class="font-weight-bold pl-2 text-md">市話</div>
                      <div class="text-md">
                        <v-text-field
                          v-model="item.phone_1"
                          outlined
                          dense
                          hide-details
                          class="text-typo font-size-input border rounded-0"
                        ></v-text-field>
                      </div>
                      <div class="text-md">
                        <v-text-field
                          v-model="item.phone_2"
                          outlined
                          dense
                          hide-details
                          class="text-typo font-size-input border rounded-0"
                        ></v-text-field>
                      </div>
                    </td>
                    <td
                      :class="{ 'border-bottom': i != items.length - 1 }"
                      class="px-0"
                      width="18%"
                    >
                      <div class="font-weight-bold pl-2 text-md">地址</div>
                      <div class="text-md">
                        <v-text-field
                          v-model="item.address_1"
                          outlined
                          dense
                          hide-details
                          class="text-typo font-size-input border rounded-0"
                        ></v-text-field>
                      </div>
                      <div class="text-md">
                        <v-text-field
                          v-model="item.address_2"
                          outlined
                          dense
                          hide-details
                          class="text-typo font-size-input border rounded-0"
                        ></v-text-field>
                      </div>
                    </td>
                    <td
                      :class="{ 'border-bottom': i != items.length - 1 }"
                      class="px-0"
                    >
                      <div class="font-weight-bold pl-2 text-md">行業/公司</div>
                      <div class="text-md">
                        <v-text-field
                          v-model="item.industry"
                          outlined
                          dense
                          hide-details
                          class="text-typo font-size-input border rounded-0"
                        ></v-text-field>
                      </div>
                      <div class="text-md">
                        <v-text-field
                          v-model="item.company"
                          outlined
                          dense
                          hide-details
                          class="text-typo font-size-input border rounded-0"
                        ></v-text-field>
                      </div>
                    </td>
                    <td
                      :class="{ 'border-bottom': i != items.length - 1 }"
                      width="10%"
                      class="px-0"
                    >
                      <div class="font-weight-bold pl-2 text-md">職稱/性別</div>
                      <div class="text-md">
                        <v-text-field
                          v-model="item.job"
                          outlined
                          dense
                          hide-details
                          class="text-typo font-size-input border rounded-0"
                        ></v-text-field>
                      </div>
                      <div class="text-md">
                        <v-select
                          :items="genders"
                          v-model="item.gender"
                          outlined
                          dense
                          hide-details
                          clearable
                          class="text-typo font-size-input border rounded-0"
                        >
                        </v-select>
                      </div>
                    </td>
                    <td
                      :class="{ 'border-bottom': i != items.length - 1 }"
                      width="15%"
                      class="px-0"
                    >
                      <div class="font-weight-bold pl-2 text-md">
                        身分證/生日
                      </div>
                      <div class="text-md">
                        <v-text-field
                          v-model="item.id_full"
                          outlined
                          dense
                          hide-details
                          class="text-typo font-size-input border rounded-0"
                        ></v-text-field>
                      </div>
                      <div class="text-md">
                        <v-menu
                          v-model="item.menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              outlined
                              dense
                              hide-details
                              clearable
                              class="text-typo font-size-input border rounded-0"
                              :value="displayChineseYear(item.birth)"
                              v-bind="attrs"
                              v-on="on"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.birth"
                            :year-format="formatChineseYear"
                            locale="zh-tw"
                            @input="item.menu = false"
                          >
                          </v-date-picker>
                        </v-menu>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text> </v-card
      ></v-tab-item>
      <!-- display images tab -->
      <v-tab-item key="image" class="py-2">
        <v-card>
          <v-card-title class="justify-center py-2 px-4">
            <v-spacer></v-spacer>
            <v-btn
              :ripple="false"
              elevation="0"
              color="#fff"
              class="
                font-weight-bolder
                btn-default
                bg-gradient-primary
                py-5
                px-4
                mx-2
              "
              small
              @click="openImportDialog(2)"
            >
              上傳圖檔
              <v-icon right>mdi-upload</v-icon>
            </v-btn>
            <v-btn
              :ripple="false"
              elevation="0"
              color="#fff"
              class="
                font-weight-bolder
                btn-default btn-outline-secondary
                py-5
                px-4
                mx-2
              "
              small
              @click="reset()"
            >
              {{ $t("Reset") }}
              <v-icon right>mdi-close-circle-outline </v-icon>
            </v-btn>
            <v-btn
              :ripple="false"
              elevation="0"
              color="#fff"
              class="
                font-weight-bolder
                btn-default
                bg-gradient-danger
                py-5
                px-6
                mx-3
                ms-auto
              "
              :disabled="files.length == 0"
              small
              @click="confirmAction(action.process_batch)"
            >
              <v-icon class="me-2" size="12">fa fa-upload</v-icon>
              批次處理
            </v-btn>
            <v-btn
              :ripple="false"
              elevation="0"
              color="#fff"
              class="
                font-weight-bolder
                btn-default
                bg-gradient-grt
                py-5
                px-6
                mx-3
                ms-auto
              "
              small
              :disabled="!is_allow_merge"
              @click="confirmAction(action.merge_excel)"
            >
              <v-icon class="me-2" size="12">fa fa-upload</v-icon>
              合併Excel
            </v-btn>
          </v-card-title>
          <v-card-text class="px-2 py-0" v-if="files.length > 0">
            <v-simple-table class="table border-radius-xl">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>
                      <p
                        class="
                          mb-0
                          text-body text-capitalize text-md
                          font-weight-normal
                        "
                      >
                        檔名
                      </p>
                    </th>
                    <th>
                      <p
                        class="
                          mb-0
                          text-body text-capitalize text-md
                          font-weight-normal
                        "
                      >
                        任務ID
                      </p>
                    </th>

                    <th>
                      <p
                        class="
                          mb-0
                          text-body text-capitalize text-md
                          font-weight-normal
                        "
                      >
                        狀態
                      </p>
                    </th>

                    <th>
                      <p
                        class="
                          mb-0
                          text-body text-capitalize text-md
                          font-weight-normal
                        "
                      >
                        操作
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in files" :key="i" class="px-2">
                    <td class="px-0" width="35%">
                      <v-text-field
                        v-model="item.page"
                        outlined
                        dense
                        hide-details
                        class="text-typo font-size-input border rounded-0"
                      ></v-text-field>
                    </td>
                    <td class="px-0" width="15%">
                      <v-text-field
                        v-model="item.task_id"
                        outlined
                        dense
                        hide-details
                        class="text-typo font-size-input border rounded-0"
                        readonly
                      ></v-text-field>
                    </td>

                    <td class="px-0">
                      <v-text-field
                        :value="getStatus(item.status)"
                        outlined
                        dense
                        hide-details
                        class="text-typo font-size-input border rounded-0"
                      ></v-text-field>
                    </td>
                    <td class="px-0" width="25%">
                      <v-btn
                        elevation="0"
                        :ripple="false"
                        class="
                          font-weight-bold
                          text-uppercase
                          btn-default
                          bg-gradient-default
                          mx-2
                        "
                        @click="previewItem(item)"
                      >
                        <v-icon class="me-2" size="12">fa fa-eye</v-icon>
                        檢視圖片</v-btn
                      >
                      <v-btn
                        elevation="0"
                        :ripple="false"
                        class="
                          font-weight-bold
                          text-uppercase
                          btn-default
                          bg-gradient-secondary
                          mx-2
                        "
                        @click="exportExcelByID(item)"
                      >
                        <v-icon class="me-2" size="12">fa fa-file-excel</v-icon>
                        匯出</v-btn
                      >
                      <v-btn
                        elevation="0"
                        :ripple="false"
                        class="
                          font-weight-bold
                          text-uppercase
                          btn-default
                          bg-gradient-danger
                          mx-2
                        "
                        @click="removeFile(i)"
                      >
                        <v-icon class="me-2" size="12">fa fa-times</v-icon>
                        移除</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text></v-card
        >
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import CommonUtility from "@/util/CommonUtility.js";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
import { Cropper } from "vue-advanced-cropper";
import CustomerGroupMixin from "@/views/Pages/CRM/CustomerGroupMixin.vue";
import CustomerGroup from "@/views/Pages/CRM/Admin/CustomerGroup.vue";
import "vue-advanced-cropper/dist/style.css";
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Dropzone from "@/views/Pages/CRM/Widgets/Dropzone.vue";
import { mapGetters } from "vuex";
import {
  phone_type_enum,
  import_option_enum,
  import_option_enum_str,
  image_source_enum,
  image_source_enum_str,
  data_source_enum_str,
} from "@/definitions.js";
import {
  photo_type_enum,
  photo_type_enum_str,
  ocr_status_enum,
  ocr_status_enum_str,
} from "@/case.js";
export default {
  name: "customer-import",
  props: {
    auto_search: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    data_source_enum_str: data_source_enum_str,
    phone_type_enum: phone_type_enum,
    import_option_enum: import_option_enum,
    import_option_enum_str: import_option_enum_str,
    image_source_enum: image_source_enum,
    image_source_enum_str: image_source_enum_str,
    ocr_status_enum: ocr_status_enum,
    ocr_status_enum_str: ocr_status_enum_str,
    page: 1,
    itemsPerPage: 20,
    items_count: 0,
    items: [],
    options: {},
    customer_loading: false,
    import_dialog: false,
    import_option: 2,
    data_source_option: 0,
    data_source_str: "公開",
    groups: null,
    loading_dialog: {
      dialog: false,
      title: null,
      message: null,
      show_loading: true,
      show_confirm: false,
      confirm_function: null,
    },
    confirm: {
      dialog: false,
      title: null,
      message: null,
      function: null,
    },
    headers: [
      {
        text: "姓名",
        value: "full_name",
        class: "text-typo font-weight-bolder",
      },
      {
        text: "手機1",
        value: "mobile_1",
        class: "text-typo font-weight-bolder",
      },
      {
        text: "手機2",
        value: "mobile_2",
        class: "text-typo font-weight-bolder",
      },
      {
        text: "市話1",
        value: "phone_1",
        class: "text-typo font-weight-bolder",
      },
      {
        text: "市話2",
        value: "phone_2",
        class: "text-typo font-weight-bolder",
      },
      {
        text: "地址1",
        value: "address_1",
        class: "text-typo font-weight-bolder",
      },
      {
        text: "地址2",
        value: "address_2",
        class: "text-typo font-weight-bolder",
      },
      {
        text: "行業",
        value: "industry",
        class: "text-typo font-weight-bolder",
      },
      {
        text: "email",
        value: "email",
        class: "text-typo font-weight-bolder",
      },
    ],
    file: null,
    imageUrl: null,
    rotation: 0,
    coordinates: null,
    // interval
    intervalId: null,
    state: ocr_status_enum.Upload,
    content: "",
    tryCount: 0, // 添加计数器
    task_id_array: null,
    currentTaskIndex: 0,
    file_name: null,
    genders: [
      { text: "未知", value: 0 },
      { text: "女", value: 1 },
      { text: "男", value: 2 },
    ],
    searchable: true,
    files: [],
    defaultPosition: { left: 0, top: 0 },
    preview_dialog: false,
    preview_photo: null,
    tab: null,
    action: {
      upload_image: 1,
      process_batch: 2,
      merge_excel: 3,
      import_excel: 4,
    },
    object_type_filter: [
      { text: "獅子會", value: 0 },
      { text: "扶輪社", value: 1 },
    ],
  }),
  components: {
    CommonLoading,
    CommonConfirm,
    Treeselect,
    CustomerGroup,
    Dropzone,
    Cropper,
  },
  beforeDestroy() {
    this.stopPolling();
    this.reset();
    console.log("stop the polling");
  },
  mounted() {
    this.getAllGroups();
  },
  mixins: [CustomerGroupMixin],
  computed: {
    is_allow_merge() {
      // console.log("is_allow_merge", this.state);
      return this.files.length > 0 && this.state == ocr_status_enum.Done;
    },
  },
  methods: {
    ...mapGetters(["getToken"]),
    getPageCount() {
      return Math.ceil(this.items_count / this.itemsPerPage);
    },
    rowClass() {
      console.log("rowClass");
      return "text-typo mx-0 px-0";
    },
    handleDrop(event) {
      const droppedFiles = event.dataTransfer.files;
      this.files = [...this.files, ...droppedFiles];
      // 自行觸發 v-file-input 的 change 事件來處理文件變化
      this.$refs.fileInput.$emit("change", this.files);
      console.log("Files dropped:", this.files);
    },
    confirmAction(action) {
      console.log("action", action);
      let title = "";
      let msg = "";
      let confirm_func = () => {};
      switch (action) {
        case this.action.process_batch:
          title = "處理批次";
          msg = "確定要處理批次嗎?";
          confirm_func = this.onProcessImages;
          break;
        case this.action.merge_excel:
          title = "合併資料";
          msg = "確定要合併資料嗎?";
          confirm_func = this.onMergeExcel;
          break;
        case this.action.import_excel:
          title = "匯入資料";
          msg = "確定要匯入資料嗎?";

          break;
      }
      this.confirm = {
        dialog: true,
        title: title,
        message: msg,
        show_confirm: true,
        show_loading: false,
        function: confirm_func,
      };
    },
    showConfirmProgress(title, msg) {
      this.confirm.dialog = false;
      this.loading_dialog = {
        dialog: true,
        title: title,
        message: msg,
        show_loading: true,
        show_confirm: false,
      };
    },
    importExcel() {
      // /api/v1/customers-import/excel
      console.log("importExcel", this.file);

      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-import/excel`;
      const formData = new FormData();
      formData.append("file", this.file);
      this.doHttpPost(url, formData, this.uploadDone);
    },
    uploadDone(res) {
      // add data_source_str into item of data array
      let data = res.data;
      for (let i = 0; i < data.length; i++) {
        data[i].data_source = this.data_source_str;
        data[i].menu = false;
      }
      this.groups = res.groups;
      this.items = data;
      this.items_count = data.length;
    },
    importImage(blob) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-import/image`;
      const formData = new FormData();
      let new_filename = this.file.name.split(".").slice(0, -1).join(".");
      new_filename = new_filename + ".jpg";
      formData.append("image", blob, new_filename);
      formData.append("data_source_option", this.data_source_option);
      this.loading_dialog = {
        dialog: true,
        title: "匯入中",
        message: "匯入中",
        show_loading: true,
        show_confirm: false,
      };
      console.log("importImage", this.data_source_option);

      this.doHttpPost(
        url,
        formData,
        this.importImageDone,
        this.importImageFail
      );
    },
    importImageDone(data) {
      // this.loading_dialog = {
      //   dialog: true,
      //   title: "上傳成功",
      //   message: "上傳成功",
      //   show_loading: false,
      //   show_confirm: true,
      //   confirm_function: this.onImportConfirmDone,
      // };
      this.loading_dialog.dialog = false;
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      data.img = canvas.toDataURL(); // 获取截图的dataURL
      console.log("importImageDone", data);
      this.files.push(data);
    },
    importImageFail(data) {
      const msg = CommonUtility.parseError2(data);
      this.loading_dialog = {
        dialog: true,
        title: "上傳失敗",
        message: msg,
        show_loading: false,
        show_confirm: true,
      };
    },
    async fetchStatus(task_id) {
      try {
        const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-import/${task_id}/status`;
        let config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.getToken(),
          },
        };
        const response = await this.axios.get(url, config);
        const status = response.data.status;
        // const content = response.data.content;
        console.log(`Task ID: ${task_id} - Status:`, status);
        this.tryCount++;
        if (this.tryCount >= 30) {
          console.log("fail to get the file , try another");
          return null;
        }
        switch (status) {
          case ocr_status_enum.Done:
            console.log(`Task ID: ${task_id} - response.data`, response.data);

            break;
          case ocr_status_enum.FailUpload:
          case ocr_status_enum.FailProcess:
            console.log("Fail to upload the file ");
            return null;
          default:
            console.log("Task ID: ", task_id, " - Status: ", status);
            break;
        }
        // update the status of the file
        console.log("check id", task_id);
        this.files.forEach((file) => {
          console.log("file", file);
          if (file.id == task_id) {
            file.status = status;
            file.task_id = response.data.task_id;
          }
        });

        return status;
      } catch (error) {
        console.error(`Error fetching status for task_id ${task_id}:`, error);
        return null;
      }
    },
    checkStatus(task_id) {
      this.intervalId = setInterval(async () => {
        const status = await this.fetchStatus(task_id);
        if (status === 2 || status == null) {
          clearInterval(this.intervalId);
          this.currentTaskIndex++;
          if (this.currentTaskIndex < this.task_id_array.length) {
            this.checkStatus(this.task_id_array[this.currentTaskIndex]);
          } else {
            console.log("All tasks completed.");
            this.onImportDone();
          }
        }
      }, 5000); // 每秒钟调用一次
    },
    startPolling() {
      console.log("start pooling..");
      this.state = ocr_status_enum.Processing;
      clearInterval(this.intervalId);
      this.currentTaskIndex = 0;
      this.tryCount = 0;
      if (this.task_id_array.length > 0) {
        this.checkStatus(this.task_id_array[this.currentTaskIndex]);
      } else {
        this.onImportDone();
      }
    },
    stopPolling() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.file_name = null;
        this.task_id_array = null;
        this.currentTaskIndex = 0;
        this.tryCount = 0;
      }
    },
    reset() {
      console.log("reset");
      this.items = [];
      this.items_count = 0;
      this.file = null;
      this.preview_photo = null;
      this.preview_dialog = false;
      this.imageUrl = null;
      this.files = [];
      this.state = ocr_status_enum.Upload;
      this.groups = null;
    },
    onSubmit() {
      this.confirm = {
        dialog: true,
        title: "確認匯入",
        message: "確認匯入客戶資料?",
        function: this.onImportConfirm,
      };
    },
    onImportConfirm() {
      this.confirm.dialog = false;
      this.loading_dialog = {
        dialog: true,
        title: "匯入中",
        message: "匯入中",
        show_loading: true,
        show_confirm: false,
      };
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-import/`;
      let info = this.getInfo();
      this.doHttpPost(url, info, this.onImportDone, this.onImportFail);
    },
    onImportDone(data) {
      // this.reset();
      this.state = ocr_status_enum.Done;
      this.loading_dialog = {
        dialog: true,
        title: "匯入成功",
        message: "匯入成功",
        show_loading: false,
        show_confirm: true,
        confirm_function: this.onImportConfirmDone,
      };
    },
    onImportConfirmDone() {
      console.log("onImportConfirmDone");
      this.reset();
    },
    onImportFail(data) {
      this.state = ocr_status_enum.FailUpload;
      const msg = CommonUtility.parseError2(data);
      this.loading_dialog = {
        dialog: true,
        title: "更新失敗",
        message: msg,
        show_loading: false,
        show_confirm: true,
      };
    },
    getInfo() {
      let info = [];
      // for loop this.items and if the item is not null then push to info
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        // create addresses and phones array of item
        item.addresses = [];
        item.phones = [];
        // Keys to search for and their associated actions
        const mappings = {
          address_1: { array: "addresses", field: "address" },
          address_2: { array: "addresses", field: "address" },
          phone_1: { array: "phones", field: "number", type: "Home" },
          phone_2: { array: "phones", field: "number", type: "Home" },
          mobile_1: { array: "phones", field: "number", type: "Mobile" },
          mobile_2: { array: "phones", field: "number", type: "Mobile" },
        };

        for (let key in item) {
          if (
            item.hasOwnProperty(key) &&
            mappings.hasOwnProperty(key) &&
            item[key] != null
          ) {
            const mapping = mappings[key];
            const targetArray = item[mapping.array];

            const newItem = { [mapping.field]: item[key] };
            if (mapping.type) {
              newItem.type = this.phone_type_enum[mapping.type];
            }

            targetArray.push(newItem);
          }
        }
        // groups
        item.groups = [];
        // if groups is not null then push to groups
        if (this.groups != null) {
          this.groups.forEach((group_id) => {
            item.groups.push({ group_id: group_id, owner_id: null, id: null });
          });
        }

        item.data_source = this.data_source_str;

        info.push(item);
      }
      return info;
    },
    getSuffix() {
      switch (this.import_option) {
        case import_option_enum.Excel:
          return ".xlsx";
        case import_option_enum.Csv:
          return ".csv";
        case import_option_enum.Image:
          return ".jpg,.png,.jpeg";
      }
    },
    openImportDialog(option) {
      this.import_option = option;
      this.imageUrl = null;
      this.file = null;
      this.import_dialog = true;
    },
    closeImportDialog() {
      this.import_dialog = false;
      this.import_option = 2;
      this.data_source_option = 0;
      this.coordinates = null;
    },
    onConfirm() {
      switch (this.import_option) {
        case import_option_enum.Excel:
          this.importExcel();
          this.closeImportDialog();
          break;
        case import_option_enum.Csv:
          // this.importCsv();
          break;
        case import_option_enum.Image:
          this.uploadCroppedImage();
          // alert("尚未開放");
          break;
      }
    },
    downloadTemplates() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-import/download-template`;
      // this.doHttpGet(url, {}, this.downloadTemplatesDone);
      let filename = "匯入客戶.xlsx";
      this.doDownloadFile(url, {}, filename);
    },
    onImageChange(file) {
      if (file && this.import_option == import_option_enum.Image) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };
      }
    },
    change({ coordinates, canvas }) {
      this.coordinates = coordinates;
    },
    uploadCroppedImage() {
      if (this.imageUrl && this.coordinates) {
        const currentObj = this;
        const img = new Image();
        // img.src = this.imageUrl;

        const { coordinates, canvas } = this.$refs.cropper.getResult();
        canvas.toBlob(
          (blob) => {
            // 你可以使用這個 blob 來上傳你的圖片，例如使用 Axios 或其他方法。
            currentObj.importImage(blob);
            currentObj.closeImportDialog();
          },
          "image/jpeg",
          0.8
        );
        // img.onload = () => {
        //   const tmpCanvas = document.createElement("canvas");
        //   tmpCanvas.width = this.coordinates.width;
        //   tmpCanvas.height = this.coordinates.height;
        //   const ctx = tmpCanvas.getContext("2d");
        //   ctx.drawImage(
        //     img,
        //     this.coordinates.left,
        //     this.coordinates.top,
        //     this.coordinates.width,
        //     this.coordinates.height,
        //     0,
        //     0,
        //     this.coordinates.width,
        //     this.coordinates.height
        //   );
        //   tmpCanvas.toBlob(
        //     (blob) => {
        //       // 你可以使用這個 blob 來上傳你的圖片，例如使用 Axios 或其他方法。
        //       console.log("upload blob");
        //       currentObj.importImage(blob);
        //       currentObj.closeImportDialog();
        //     },
        //     "image/jpeg",
        //     0.8
        //   );
        // };
      }
    },
    formatChineseYear(val) {
      return CommonUtility.formatChineseYear(val);
    },
    displayChineseYear(val) {
      return CommonUtility.displayChineseYear(val);
    },
    rotateImageLeft() {
      this.$refs.cropper.rotate(-0.1);
    },
    rotateImageRight() {
      // this.imageUrl = this.rotateFunction(this.imageUrl, 90);
      this.$refs.cropper.rotate(0.1);
    },
    refreshGroup(groups) {
      this.group_options = groups;
    },
    onChange(value) {
      console.log("onChange", value);
      console.log("this.files", this.files);
    },
    getFileName(item) {
      if (item.files.length > 0) {
        return item.files[0].name;
      }
      return "";
    },
    defaultSize() {
      if (
        this.$refs.cropper.imageAttributes == null ||
        this.$refs.cropper.imageAttributes == undefined
      ) {
        return {
          width: 1000,
          height: 1200,
        };
      }
      let image = this.$refs.cropper.imageAttributes;
      return {
        width: image.width,
        height: image.height,
      };
    },
    getStatus(status) {
      return CommonUtility.getText(status, ocr_status_enum_str);
    },
    onProcessImages() {
      console.log("onProcessImages");
      this.showConfirmProgress("處理批次", "處理批次中...");
      // iterate the files
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        this.processImportImage(file.id);
      }
      console.log("all files are processed");
      this.task_id_array = this.files
        .filter((file) => file.status < 2)
        .map((file) => file.id);
      // this.task_id_array = this.files.map((file) => file.id);
      console.log("task_id_array", this.task_id_array);
      this.startPolling();
    },
    processImportImage(id) {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-import/${id}/process-image`;
      let data = { id: id };

      this.doHttpPost(
        url,
        data,
        this.processImportImageDone,
        this.processImportImageFail
      );
    },
    processImportImageDone(data) {
      this.task_id = data.id;
      this.file_name = data.file_name;
      console.log("check all image status done", data);
    },
    processImportImageFail(data) {},
    exportExcelByID(item) {
      let id = item.id;
      let filename = item.page + ".xlsx";
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-import/${id}/image-excel-import`;
      this.doDownloadFile(url, {}, filename);
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    previewItem(item) {
      this.preview_photo = item.img;
      this.preview_dialog = true;
    },
    onMergeExcel() {
      this.showConfirmProgress("匯出excel", "處理匯出中...");
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/customers-import/image-excel-import-merge`;
      // get all ids in this.files
      let ids = this.files.map((file) => file.id);
      let para = {
        ids: ids.join(","),
      };
      let filename = "合併匯入.xlsx";
      // this.doHttpPost(url, data, this.onMergeExcelDone, this.onMergeExcelFail);
      this.doDownloadFile(url, para, filename, this.onMergeExcelDone);
    },
    onMergeExcelDone(data) {
      console.log("onMergeExcelDone");
      //
      // delay 1 second to set this.loading_dialog.dialog = false;
      setTimeout(() => {
        this.loading_dialog = {
          dialog: true,
          title: "匯出成功",
          message: "匯出成功",
          show_loading: false,
          show_confirm: true,
        };
      }, 1000);
    },
    onMergeExcelFail(data) {},
  },
};
</script>

<style scoped>
.vertical-align-top {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cropper {
  height: 400px;
  width: 400px;
  background: #ddd;
}
</style>
